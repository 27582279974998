body {
	overflow: hidden;
	height: 100%;
}
#Root {
	height: 100%;
}
.App {
	text-align: center;
	height: 100%;
}

.streaming-player {
	height: 100%;
}

.streaming-player__thumbnail {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 1;
}

.pause-icon {
	opacity: 0;
}

.streaming-player__thumbnail:hover .pause-icon {
	opacity: 1;
	display: block;
	animation-name: fadeIn;
	animation-fill-mode:forwards;
	animation-duration: 1s;
}

.streaming-player__thumbnail i {
	color: white;
	font-size: 20vh;
}

.streaming-player__main {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.loading, .error {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1,
}
.loading i, .error i {
	color: white;
	font-size: 20vh;
}
.error p {
	color: white;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}


.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}



/*フェードインアニメ*/
@keyframes fadeIn{
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
}